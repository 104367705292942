import React, { useEffect, useState } from "react";
import './TopRecWinner.css'
import AvatarVS from '../images/avatarVS.svg';
import DefaultProfilePic from '../images/Profile.png';
import axios from "axios";

const TopRecWinner = () => {
    const [challengedata, setChallengeData] = useState([]);
    const [userfunddata,setUserFundData] = useState([]);
    const [dotCount, setDotCount] = useState(0);
      useEffect(() => {
        getlatestfivechallData();
        getlatestfundUsers();
      }, []);
      useEffect(() => {
        const interval = setInterval(() => {
          setDotCount((prev) => (prev + 1) % 4); // Cycle from 0 to 3
        }, 500); // Update every 500ms
    
        return () => clearInterval(interval); // Clean up on unmount
      }, []);
      const getlatestfivechallData = async () => {
        const latestchaldata = await axios.post("/api/auth/getlatestfivechallenges");
        setChallengeData(latestchaldata?.data);
      }
      const getlatestfundUsers = async () => {
        const latestusersfunddata = await axios.post("/api/auth/getlatestmorefundusers");
        setUserFundData(latestusersfunddata?.data);
      }
      const getsolochallengetext = (challengeData) => {
        if(challengeData?.oneplayercompetitivegames){
        return <div className="challenge-desc"><span style={{fontSize:14,color:'#666666'}}>1v1 Challenge:<br/></span>Win {challengeData?.oneplayercompetitivegames} game{challengeData?.oneplayercompetitivegames > 1 ? 's' : ''}</div>
        } else if (challengeData?.oneplayercompetitivegoals){
          return <div className="challenge-desc"><span style={{fontSize:14,color:'#666666'}}>1v1 Challenge:<br/></span>Score {challengeData?.oneplayercompetitivegoals} goal{challengeData?.oneplayercompetitivegoals > 1 ? 's' : ''}</div>
        } else if (challengeData?.twoplayercompetitivegames){
          return <div className="challenge-desc"><span style={{fontSize:14,color:'#666666'}}>2v2 Challenge:<br/></span>Win {challengeData?.twoplayercompetitivegames} game{challengeData?.twoplayercompetitivegames > 1 ? 's' : ''}</div>
        } else if (challengeData?.twoplayercompetitivegoals){
          return <div className="challenge-desc"><span style={{fontSize:14,color:'#666666'}}>2v2 Challenge:<br/></span>Score {challengeData?.twoplayercompetitivegoals} goal{challengeData?.twoplayercompetitivegoals > 1 ? 's' : ''}</div>
        } else if (challengeData?.solocompetitivesaves){
          return <div className="challenge-desc"><span style={{fontSize:14,color:'#666666'}}>1v1 Challenge:<br/></span>Get {challengeData?.solocompetitivesaves} save{challengeData?.solocompetitivesaves > 1 ? 's' : ''}</div>
        } else if (challengeData?.duocompetitivesaves){
          return <div className="challenge-desc"><span style={{fontSize:14,color:'#666666'}}>2v2 Challenge:<br/></span>Get {challengeData?.duocompetitivesaves} save{challengeData?.duocompetitivesaves > 1 ? 's' : ''}</div>
        } else if (challengeData?.duocompetitiveassists){
          return <div className="challenge-desc"><span style={{fontSize:14,color:'#666666'}}>2v2 Challenge:<br/></span>Get {challengeData?.duocompetitiveassists} assist{challengeData?.duocompetitiveassists > 1 ? 's' : ''}</div>
        } else {
          console.log(challengeData);
          return <div className="challenge-desc"><span style={{fontSize:14,color:'#666666'}}>New Game Mode</span></div>
        }
      }
  return (
    <>
    <section className="top-earner">
            <div className='container mt-10'>
            <h2 className='section-title new-title d-flex align-items-center justify-content-center'>
            Recent Winners <span>Turn every win into profit!</span>
            </h2>
            <div className='recent-winner d-flex align-items-center justify-content-center'>
                <div className='recent-winner-inner-wrapper d-flex flex-wrap align-items-center justify-content-center'>
                {challengedata.length!=0 ?
                  challengedata.map((item, index) => (
                    <div className='recent-block'>
                      <div className=' recent-winner-top d-flex flex-wrap align-items-center justify-content-center'>
                          <div className='recent-user me-2'><img loading="lazy" src={item?.winner?.avatar ? `${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${item?.winner?.avatar}` : DefaultProfilePic} alt='padlock' /></div>
                          {item?.category!=="solo" && <div className='recent-user-vs'><img src={AvatarVS} loading="lazy" alt='padlock'/></div>}
                          {item?.category!=="solo" && <div className='recent-user'><img loading="lazy" src={item?.loser?.avatar ? `${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${item?.loser?.avatar}` : DefaultProfilePic} alt='padlock' /></div>}
                          {item?.category==="solo" && getsolochallengetext(item)}
                      </div>
                      <div className='divider-vs'></div>
                      <div className='recent-winner-bottom d-flex flex-wrap align-items-center'>
                        <h3>{item?.winner?.username}</h3>
                        <span>won</span>
                        <div>${item?.totalwager}!</div>
                      </div>
                    </div>
                  ))
                :
                  <div className="recent-winners-loading">Loading{".".repeat(dotCount)}</div>
                }
                </div>
            </div>
            </div>
        </section>

        <section className='top-earner'>
            <div className='container'>
            <h2 className='section-title new-title d-flex align-items-center justify-content-center'>
            Top Earners<span>Instant payouts anytime!</span>
            </h2>
            <div className='recent-winner d-flex align-items-center justify-content-center'>
                <div className='recent-winner-inner-wrapper new-wrapper d-flex flex-wrap align-items-center justify-content-center'>
                {userfunddata.length!=0 ?
                  userfunddata.map((item, index) => (   
                    <div className='recent-block'>
                      <div className=' recent-winner-top d-flex flex-wrap align-items-center'>
                        <div className='recent-user'><img loading="lazy" src={`${process.env.REACT_APP_BUCKET_FILE_PATH}images/avatar/${item?.user?.avatar}`} alt='padlock'/></div>
                        <h3>{item?.user?.username}</h3>
                      </div>
                      <div className='add-white-space'></div>
                      <div className='recent-winner-bottom'>
                        <div className='mx-auto'>${item.winneramount}</div>
                      </div>
                    </div>
                  ))
                :
                  <div className="recent-winners-loading">Loading{".".repeat(dotCount)}</div>
                }
                </div>
            </div>
            </div>
        </section>      
    </>
  )
}

export default TopRecWinner
